import { CRUDPathSegmentEn } from '../../newFeatures/crudPathSegment.enum';

const marketingPath = {
  marketing: '/crm/marketing',

  // Event
  event: '/crm/marketing/event',
  eventDetail: '/crm/marketing/event' + CRUDPathSegmentEn.DETAIL,
  eventDetailCode: '/crm/marketing/event' + CRUDPathSegmentEn.DETAIL_BY_CODE,
  eventAdd: '/crm/marketing/event' + CRUDPathSegmentEn.ADD,
  eventEdit: '/crm/marketing/event' + CRUDPathSegmentEn.EDIT,

  // Event type
  eventType: '/crm/marketing/event-type',

  // Visitors
  visitor: '/crm/marketing/visitor',
  visitorDetail: '/crm/marketing/visitor' + CRUDPathSegmentEn.DETAIL,
  visitorAdd: '/crm/marketing/visitor' + CRUDPathSegmentEn.ADD,
  visitorEdit: '/crm/marketing/visitor' + CRUDPathSegmentEn.EDIT
};
export default marketingPath;
