import { useQuery } from '@tanstack/react-query';
import { Select, type SelectProps } from 'antd';
import { getWorkAssignsDropdown } from 'src/apis/erp/workAssign.api';

type Props = SelectProps & {
  returnValueType?: 'code' | 'id' | 'object';
};

export default function WorkAssignSelect({
  value,
  onChange,
  style,
  allowClear = true,
  placeholder,
  disabled,
  returnValueType
}: Props) {
  const workAssignsDropdownQuery = useQuery({
    queryKey: ['workAssignsDropdown'],
    queryFn: () => getWorkAssignsDropdown(),
    staleTime: 60 * 1000
  });

  return (
    <Select
      disabled={disabled}
      allowClear={allowClear}
      value={workAssignsDropdownQuery.isFetching ? undefined : value}
      style={style}
      onChange={onChange}
      options={workAssignsDropdownQuery.data?.data.map((item) => {
        return {
          value: returnValueType === 'code' ? item.code : returnValueType === 'object' ? JSON.stringify(item) : item.id,
          label: item.name
        };
      })}
      showSearch
      filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase())}
      placeholder={placeholder}
      loading={workAssignsDropdownQuery.isFetching}
    />
  );
}
