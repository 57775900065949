export enum CategoryEnum {
  NVL = 1, // Nguyên Vật Liệu
  BP = 2, // Business Partner
  TPHH = 3, // Thành phẩm hàng hóa
  Other = 4
}

export enum ItemMasterDataTabEnum {
  General = 1,
  Accounting = 2,
  Production = 3,
  WareHouse = 4,
  ResearchAndDevelopment = 5,
  Sell = 6,
  Purchase = 7,
  Logistics = 8
}
