import type { IWorkAssign, WorkAssignAdd, WorkAssignShort } from 'src/types/erp/workAssign.type';
import type { PagedList } from 'src/types/pagedList.type';
import { http } from 'src/utils/http';

const controller = 'helpdesk/assign-tasks';

export const getWorkAssignsDropdown = async () => {
  return await http.get<WorkAssignShort[]>(`${controller}/dropdown`);
};

export const getWorkAssigns = async (
  sequenceStatus: string,
  searchCondition: string | undefined,
  page: number,
  pageSize: number,
  orderBy?: string
) => {
  return await http.get<PagedList<IWorkAssign>>(controller, {
    params: {
      sequenceStatus,
      searchCondition,
      page,
      pageSize,
      orderBy
    }
  });
};

export const getWorkAssign = async (id: number) => {
  return await http.get<IWorkAssign>(`${controller}/${id}`);
};

export const addWorkAssign = async (workAssign: WorkAssignAdd) => {
  return await http.post(controller, workAssign);
};

export const updateWorkAssign = async (id: number, workAssign: WorkAssignAdd) => {
  return await http.put(`${controller}/${id}`, workAssign);
};

export const deleteWorkAssign = async (id: number) => {
  return await http.delete(`${controller}/${id}`);
};
