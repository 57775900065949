import { Button, Flex, Typography } from 'antd';
import styles from './SubHeader.module.scss';
// @ts-ignore
import { ArrowLeftOutlined, HomeOutlined, MenuFoldOutlined } from '@ant-design/icons';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { MenuDotIcon } from 'src/assets/icons';
import Icon from 'src/components/v2/Icon';
import { AppContext } from 'src/contexts/app.context';

const { Title } = Typography;

export default function SubHeader() {
  const { setIsSidebarCollapsed, currentResource, setOpenAppMenu } = useContext(AppContext);
  const navigate = useNavigate();
  return (
    <div className={styles['sub-header']}>
      <Flex justify='space-between'>
        <Flex gap={20} align='baseline'>
          <Icon url={<MenuFoldOutlined />} size={20} onClick={() => setIsSidebarCollapsed((prev) => !prev)} />
          <Icon url={<ArrowLeftOutlined />} size={20} onClick={() => navigate(-1)} />
          <Icon url={<HomeOutlined />} size={20} onClick={() => navigate('/')} />
        </Flex>
        {/* <Icon url={<HomeOutlined />} size={20} onClick={() => navigate('/')} /> */}
        <Button
          size='large'
          type='text'
          icon={<Icon url={MenuDotIcon} size={20} />}
          onClick={() => setOpenAppMenu(true)}
        >
          <Title level={3} className={styles['menu-label']}>
            {currentResource?.parent?.name}
          </Title>
        </Button>
      </Flex>
    </div>
  );
}
