import { lazy } from 'react';
import marketingPath from 'src/constants/urlPath/crm/marketingPath';
import type { RouteType } from 'src/hooks/useRouteElements';
import NewLayout from '../../layouts/NewFeatures/MainLayout';
import { PermissionEn } from 'src/constants/permission.enum';

const Marketing = lazy(() => import('src/pages/CRM/Marketing'));
const EventType = lazy(() => import('src/pages/CRM/Marketing/EventType'));
const Event = lazy(() => import('src/pages/CRM/Marketing/Event'));
const EventForm = lazy(() => import('src/pages/CRM/Marketing/Event/EventForm'));
const Visitor = lazy(() => import('src/pages/CRM/Marketing/Visitor'));
const VisitorForm = lazy(() => import('src/pages/CRM/Marketing/Visitor/VisitorForm'));

const marketingRoutes: RouteType[] = [
  {
    path: marketingPath.marketing,
    Layout: NewLayout,
    Component: Marketing
  },
  // Event type
  {
    path: marketingPath.eventType,
    Layout: NewLayout,
    Component: EventType,
    permission: PermissionEn.read
  },
  // Event
  {
    path: marketingPath.event,
    Layout: NewLayout,
    Component: Event,
    permission: PermissionEn.read
  },
  {
    path: marketingPath.eventAdd,
    Layout: NewLayout,
    Component: EventForm,
    permission: PermissionEn.create
  },
  {
    path: `${marketingPath.eventDetail}/:id`,
    Layout: NewLayout,
    Component: EventForm,
    permission: PermissionEn.viewDetail
  },
  {
    path: `${marketingPath.eventEdit}/:id`,
    Layout: NewLayout,
    Component: EventForm,
    permission: PermissionEn.update
  },

  // Visitor
  {
    path: marketingPath.visitor,
    Layout: NewLayout,
    Component: Visitor,
    permission: PermissionEn.read
  },
  {
    path: marketingPath.visitorAdd,
    Layout: NewLayout,
    Component: VisitorForm,
    permission: PermissionEn.create
  },
  {
    path: `${marketingPath.visitorDetail}/:id`,
    Layout: NewLayout,
    Component: VisitorForm,
    permission: PermissionEn.viewDetail
  },
  {
    path: `${marketingPath.visitorEdit}/:id`,
    Layout: NewLayout,
    Component: VisitorForm,
    permission: PermissionEn.update
  }
];

export default marketingRoutes;
