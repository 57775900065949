import { CalendarOutlined, CaretDownOutlined, KeyOutlined, LogoutOutlined } from '@ant-design/icons';
import { Button, Divider, Dropdown, Flex, Layout, type MenuProps, Space, Typography } from 'antd';
import { t } from 'i18next';
import { Fragment, type MouseEvent, useContext, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Add from 'src/assets/icons/add.png';
import User from 'src/assets/icons/user.png';
import Logo from 'src/assets/images/logo.png';
import Icon from 'src/components/v2/Icon';
import urlPath from 'src/constants/urlPath/urlPath';
import { AppContext } from 'src/contexts/app.context';
import { useBasePath } from 'src/hooks/useBasePath';
import ChangePassword from 'src/pages/Config/ChangePassword';
import Config from 'src/pages/v2/Config/Setting';
import TicketManagementModal from 'src/pages/v2/Home/TicketManagementModal';
import { getPathsFromRoles } from 'src/utils/utils';
import { v4 as uuid } from 'uuid';
import Notification from './Notification';
import './header.scss';
import type { UserInfo } from 'src/types/master_data/employee.type';
import { DepartmentEn } from 'src/constants/department.enum';

const { Text } = Typography;

const { Header: HeaderAntd } = Layout;

interface MenuType {
  id: string;
  label: string;
  location: string;
  submenus?: Submenu[];
  mainLocation?: string;
}

interface Submenu {
  id: string;
  label: string;
  location: string;
}

const menuHelpDeskList = (paths: string[], userInfo: UserInfo | null): MenuType[] => {
  let listHelpDeskMenu = [
    {
      id: '1',
      label: 'Ticket Raising',
      location: urlPath.myTicket.location,
      mainLocation: urlPath.ticket.location
    },
    {
      id: '2',
      label: 'HR Ticket',
      location: urlPath.hrTicket.location,
      submenus: [
        {
          id: uuid(),
          label: t('v2.sidebar.hrTicket.leaveTicket'),
          location: urlPath.myLeaveTicket.location
        },
        {
          id: uuid(),
          label: t('v2.sidebar.hrTicket.overtimeTicket'),
          location: urlPath.myOvertimeTicket.location
        },
        {
          id: uuid(),
          label: t('v2.sidebar.hrTicket.businessTripTicket'),
          location: urlPath.myBusinessTripTicket.location
        },
        {
          id: uuid(),
          label: t('v2.sidebar.hrTicket.advancePaymentTicket'),
          location: urlPath.myAdvancePaymentTicket.location
        },
        {
          id: uuid(),
          label: t('v2.sidebar.hrTicket.transportationTicket'),
          location: urlPath.myTransportationTicket.location
        },
        {
          id: uuid(),
          label: t('v2.sidebar.hrTicket.purchaseRequestTicket'),
          location: urlPath.myPurchaseRequestTicket.location
        },
        {
          id: uuid(),
          label: t('v2.sidebar.hrTicket.equipmentSupplyRequestTicket'),
          location: urlPath.myEquipmentSupplyRequestTicket.location
        },
        {
          id: uuid(),
          label: t('v2.sidebar.hrTicket.additionalWorkingTimeTicket'),
          location: urlPath.myAdditionalWorkingTimeTicket.location
        }
      ]
    },
    {
      id: '3',
      label: 'HR Chốt phiếu',
      location: urlPath.hrConfirm.location,
      submenus: [
        {
          id: uuid(),
          label: t('v2.sidebar.hrTicket.leaveTicket'),
          location: urlPath.hrConfirmLeaveTicket.location
        },
        {
          id: uuid(),
          label: t('v2.sidebar.hrTicket.overtimeTicket'),
          location: urlPath.hrConfirmOvertimeTicket.location
        },
        {
          id: uuid(),
          label: t('v2.sidebar.hrTicket.businessTripTicket'),
          location: urlPath.hrConfirmBusinessTripTicket.location
        },
        {
          id: uuid(),
          label: t('v2.sidebar.hrTicket.transportationTicket'),
          location: urlPath.hrConfirmTransportationTicket.location
        }
      ]
    }
  ];

  //#region Hr
  // Quyền vào Hr
  // !paths?.includes(urlPath.leaveTicket.location) &&
  //   !paths?.includes(urlPath.overtimeTicket.location) &&
  //   !paths?.includes(urlPath.transportationTicket.location) &&
  //   !paths?.includes(urlPath.advancePaymentTicket.location) &&
  //   !paths?.includes(urlPath.businessTripTicket.location) &&
  //   !paths?.includes(urlPath.purchaseRequestTicket.location) &&
  //   (listHelpDeskMenu = listHelpDeskMenu.filter((item) => item.location !== urlPath.hrTicket.location));

  // // Quyền vào phiếu nghỉ phép
  // !paths?.includes(urlPath.leaveTicket.location) &&
  //   (listHelpDeskMenu[1] = {
  //     ...listHelpDeskMenu[1],
  //     submenus: listHelpDeskMenu[1]?.submenus?.filter(
  //       (item) => item.id !== urlPath.myLeaveTicket.location
  //     ) as Submenu[],
  //     mainLocation: undefined
  //   });

  // Quyền vào phiếu tăng ca
  !paths?.includes(urlPath.overtimeTicket.location) &&
    (listHelpDeskMenu[1] = {
      ...listHelpDeskMenu[1],
      submenus: listHelpDeskMenu[1]?.submenus?.filter(
        (item) => item.location !== urlPath.myOvertimeTicket.location
      ) as Submenu[],
      mainLocation: undefined
    });

  // Quyền vào phiếu vận chuyển
  !paths?.includes(urlPath.transportationTicket.location) &&
    (listHelpDeskMenu[1] = {
      ...listHelpDeskMenu[1],
      submenus: listHelpDeskMenu[1]?.submenus?.filter(
        (item) => item.location !== urlPath.myTransportationTicket.location
      ) as Submenu[],
      mainLocation: undefined
    });

  // Quyền vào phiếu tạm ứng
  !paths?.includes(urlPath.advancePaymentTicket.location) &&
    (listHelpDeskMenu[1] = {
      ...listHelpDeskMenu[1],
      submenus: listHelpDeskMenu[1]?.submenus?.filter(
        (item) => item.location !== urlPath.myAdvancePaymentTicket.location
      ) as Submenu[],
      mainLocation: undefined
    });

  // Quyền vào phiếu công tác
  !paths?.includes(urlPath.businessTripTicket.location) &&
    (listHelpDeskMenu[1] = {
      ...listHelpDeskMenu[1],
      submenus: listHelpDeskMenu[1]?.submenus?.filter(
        (item) => item.location !== urlPath.myBusinessTripTicket.location
      ) as Submenu[],
      mainLocation: undefined
    });

  // Quyền vào phiếu mua hàng
  !paths?.includes(urlPath.purchaseRequestTicket.location) &&
    (listHelpDeskMenu[1] = {
      ...listHelpDeskMenu[1],
      submenus: listHelpDeskMenu[1]?.submenus?.filter(
        (item) => item.location !== urlPath.myPurchaseRequestTicket.location
      ) as Submenu[],
      mainLocation: undefined
    });

  // Quyền vào phiếu yêu cầu cấp thiết bị
  !paths?.includes(urlPath.equipmentSupplyRequestTicket.location) &&
    (listHelpDeskMenu[1] = {
      ...listHelpDeskMenu[1],
      submenus: listHelpDeskMenu[1]?.submenus?.filter(
        (item) => item.location !== urlPath.myEquipmentSupplyRequestTicket.location
      ) as Submenu[],
      mainLocation: undefined
    });

  // Quyền vào phiếu yêu cầu cấp thiết bị
  !paths?.includes(urlPath.additionalWorkingTimeTicket.location) &&
    (listHelpDeskMenu[1] = {
      ...listHelpDeskMenu[1],
      submenus: listHelpDeskMenu[1]?.submenus?.filter(
        (item) => item.location !== urlPath.myAdditionalWorkingTimeTicket.location
      ) as Submenu[],
      mainLocation: undefined
    });
  //#endregion

  //#region Hr Chốt phiếu
  // Quyền vào chốt phiếu nghỉ phép
  !paths?.includes(urlPath.hrConfirm.location) &&
    !paths?.includes(urlPath.hrConfirmLeaveTicket.location) &&
    (listHelpDeskMenu[2] = {
      ...listHelpDeskMenu[2],
      submenus: listHelpDeskMenu[2]?.submenus?.filter((item) => {
        return item.location !== urlPath.hrConfirmLeaveTicket.location;
      }) as Submenu[],
      mainLocation: undefined
    });

  // Quyền vào chốt phiếu công tác
  !paths?.includes(urlPath.hrConfirm.location) &&
    !paths?.includes(urlPath.hrConfirmBusinessTripTicket.location) &&
    (listHelpDeskMenu[2] = {
      ...listHelpDeskMenu[2],
      submenus: listHelpDeskMenu[2]?.submenus?.filter((item) => {
        return item.location !== urlPath.hrConfirmBusinessTripTicket.location;
      }) as Submenu[],
      mainLocation: undefined
    });

  // Quyền vào chốt phiếu tăng ca
  !paths?.includes(urlPath.hrConfirm.location) &&
    !paths?.includes(urlPath.hrConfirmOvertimeTicket.location) &&
    (listHelpDeskMenu[2] = {
      ...listHelpDeskMenu[2],
      submenus: listHelpDeskMenu[2]?.submenus?.filter((item) => {
        return item.location !== urlPath.hrConfirmOvertimeTicket.location;
      }) as Submenu[],
      mainLocation: undefined
    });

  // Quyền vào xác nhận vận chuyển
  // !paths?.includes(urlPath.hrConfirm.location) ||
  (!paths?.includes(urlPath.hrConfirmTransportationTicket.location) || userInfo?.departmentId !== DepartmentEn.hr) &&
    (listHelpDeskMenu[2] = {
      ...listHelpDeskMenu[2],
      submenus: listHelpDeskMenu[2]?.submenus?.filter((item) => {
        return item.location !== urlPath.hrConfirmTransportationTicket.location;
      }) as Submenu[],
      mainLocation: undefined
    });

  // Quyền vào Hr Chốt phiếu
  !paths?.includes(urlPath.hrConfirmLeaveTicket.location) && // Không có quyền hr confirm leave
    !paths?.includes(urlPath.hrConfirmBusinessTripTicket.location) && // Không có quyền hr confirm business trip
    !paths?.includes(urlPath.hrConfirmOvertimeTicket.location) && // Không có quyền hr confirm overtime
    (!paths?.includes(urlPath.hrConfirmTransportationTicket.location) || userInfo?.departmentId !== DepartmentEn.hr) &&
    (listHelpDeskMenu = listHelpDeskMenu.filter((item) => item.location !== urlPath.hrConfirm.location)); // Ẩn menu hr confirm

  //#endregion

  // #region Ticket Raising
  // !paths?.includes(urlPath.ticket.location) &&
  //   (listHelpDeskMenu = listHelpDeskMenu.filter((item) => item.mainLocation !== urlPath.ticket.location));
  // #endregion

  return listHelpDeskMenu;
};

const menuCorporateOperationList = (paths: string[]) => {
  const listCorporateOperationMenu = [
    {
      id: uuid(),
      label: 'Asset Management',
      location: urlPath.corporateOperation.location,
      mainLocation: urlPath.corporateOperation.location,
      submenus: [
        {
          id: uuid(),
          label: t('v2.officeTicket.handoverTicket'),
          location: urlPath.myHandoverTicket.location
        }
      ]
    }
  ];

  !paths?.includes(urlPath.handoverTicket.location) && listCorporateOperationMenu.splice(0, 1);

  return listCorporateOperationMenu;
};

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpenAddTicket, setIsOpenAddTicket] = useState<boolean>(false);
  const basePath = useBasePath();
  // const basePath = useBasePath();
  const [openChangePassword, setOpenChangePassword] = useState<boolean>(false);
  const [openConfig, setOpenConfig] = useState<boolean>(false);
  const isTicketPage = location.pathname.includes(urlPath.ticket.location);
  const isHrTicketPage = location.pathname.includes(urlPath.hrTicket.location);
  const isHrConfirmTicketPage = location.pathname.includes(urlPath.hrConfirm.location);
  const isOfficeTicketPage = location.pathname.includes(urlPath.corporateOperation.location);
  const isExternalPage = location.pathname === urlPath.homeSub.location || location.pathname.includes('/s/');
  const isHomePage = location.pathname === urlPath.home.location;
  const isOvertimeTicketPage = basePath?.includes(urlPath.overtimeTicket.location);
  const isBusinessTripTicketPage = basePath?.includes(urlPath.businessTripTicket.location);
  const { userInfo, roles, setTicketSelected } = useContext(AppContext);
  const isShowHeaderMenu = isTicketPage || isHrTicketPage || isOfficeTicketPage || isHrConfirmTicketPage;
  const isHelpDeskApp = location.pathname.includes(urlPath.ticketRaising.location);
  const isCorporateOperationApp = location.pathname.includes(urlPath.corporateOperation.location);
  const paths = useMemo(() => {
    return getPathsFromRoles(roles);
  }, [roles]);

  const handleNavigateBiometricLog = () => {
    navigate(urlPath.biometricLog.location);
  };

  const items: MenuProps['items'] = [
    {
      key: '1',
      onClick: () => {
        handleNavigateBiometricLog();
      },
      label: (
        <Flex className='user-profile-item'>
          <CalendarOutlined />
          <span style={{ marginLeft: 10 }}>Nhật kí chấm công</span>
        </Flex>
      )
    },
    {
      key: '2',
      onClick: () => {
        handleChangePassword();
      },
      label: (
        <Flex className='user-profile-item'>
          <KeyOutlined />
          <span style={{ marginLeft: 10 }}>Thay đổi mật khẩu</span>
        </Flex>
      )
    },
    {
      key: '3',
      onClick: () => {
        handleLogout();
      },
      label: (
        <Flex className='user-profile-item'>
          <LogoutOutlined />
          <span style={{ marginLeft: 10 }}>Đăng xuất</span>
        </Flex>
      )
    }
  ];

  const handleAdd = () => {
    const path = location.pathname;

    switch (true) {
      case path.includes(urlPath.ticket.location):
        setIsOpenAddTicket(true);
        break;
      case path.includes(urlPath.leaveTicket.location):
        navigate(urlPath.leaveTicketAdd.location);
        break;
      case path.includes(urlPath.advancePaymentTicket.location):
        navigate(urlPath.advancePaymentTicketAdd.location);
        break;
      case path.includes(urlPath.overtimeTicket.location):
        navigate(urlPath.overtimeTicketAdd.location);
        break;
      case path.includes(urlPath.businessTripTicket.location):
        navigate(urlPath.businessTripTicketAdd.location);
        break;
      case path.includes(urlPath.purchaseRequestTicket.location):
        navigate(urlPath.purchaseRequestTicketAdd.location);
        break;
      case path.includes(urlPath.transportationTicket.location):
        navigate(urlPath.transportationTicketAdd.location);
        break;
      case path.includes(urlPath.handoverTicket.location):
        navigate(urlPath.handoverTicketAdd.location);
        break;
      case path.includes(urlPath.equipmentSupplyRequestTicket.location):
        navigate(urlPath.equipmentSupplyRequestTicketAdd.location);
        break;
      case path.includes(urlPath.additionalWorkingTimeTicket.location):
        navigate(urlPath.additionalWorkingTimeTicketAdd.location);
        break;
      case path.includes(urlPath.leaveTicketSub.location):
        navigate(urlPath.leaveTicketAddSub.location);
        break;
      case path.includes(urlPath.externalWorkerTicket.location):
        navigate(urlPath.externalWorkerTicketAdd.location);
        break;
      case path.includes(urlPath.expenseTrackingRequestTicket.location):
        navigate(urlPath.expenseTrackingRequestTicketAdd.location);
        break;
      default:
        break;
    }
  };

  const handleChangePassword = () => {
    setOpenChangePassword(true);
  };

  const handleLogout = async () => {
    const returnUrl = window.location.pathname;
    localStorage.setItem('returnUrl', returnUrl);
    navigate(urlPath.signoutOidc.location);
  };

  const handleClickMenuItem = (location: string, e: MouseEvent<HTMLLIElement, globalThis.MouseEvent>) => {
    if (
      location === urlPath.myTicket.location ||
      location === urlPath.biometricLog.location ||
      location === urlPath.hrConfirmLeaveTicket.location
    ) {
      navigate(location);
    } else e.preventDefault();
  };

  const formatName = (fullName: string | undefined) => {
    if (!fullName) return '';

    const newName = fullName?.split(' ').slice(-1).join(' ');
    return newName[0] + newName.slice(1).toLowerCase();
  };

  const getMenuList = () => {
    if (isHelpDeskApp) {
      return menuHelpDeskList(paths, userInfo);
    } else if (isCorporateOperationApp) {
      return menuCorporateOperationList(paths);
    }
  };

  return (
    <HeaderAntd className={`header-wrapper ${!isHomePage ? 'collapse' : ''} `}>
      <Flex justify='flex-end' align='center' className='header'>
        <Flex justify='center' align='center' className='header-left' gap={isHomePage ? 20 : 50}>
          <Icon
            url={Logo}
            size={40}
            onClick={() => navigate(isExternalPage ? '/s' : '/')}
            className='header-logo-icon'
          />

          {isShowHeaderMenu ? (
            <Flex justify='space-between' style={{ width: '100%', animation: 'fadeIn 1s' }}>
              <ul className='header-menu-list'>
                {getMenuList()?.map((x, index, xs) => (
                  <Fragment key={x.id}>
                    <li
                      className={`header-menu-item ${
                        location.pathname.includes(x.mainLocation ? x.mainLocation : x.location) && 'actived'
                      }`}
                      onClick={(e) => {
                        // setIsTicketDetailCollapsed(true);
                        setTicketSelected(undefined);
                        handleClickMenuItem(x.location, e);
                      }}
                    >
                      {x.label}
                      {x.submenus && x.submenus?.length > 0 && (
                        <ul className='menu-dropdown'>
                          {x.submenus?.map((submenu) => (
                            <li
                              className={`header-menu-sub-item ${
                                location.pathname.includes(submenu.location) && 'actived'
                              }`}
                              key={submenu.id}
                              onClick={(e) => {
                                e.stopPropagation();
                                navigate(submenu.location);
                                // setIsTicketDetailCollapsed(true);
                                setTicketSelected(undefined);
                              }}
                            >
                              {submenu.label}
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                    {index !== xs.length - 1 && <Divider type='vertical' style={{ backgroundColor: 'white' }} />}
                  </Fragment>
                ))}
              </ul>
            </Flex>
          ) : (
            <Typography.Title
              level={5}
              style={{
                marginBottom: 0,
                color: '#fff'
              }}
            >
              MARTECH COMPANY
            </Typography.Title>
          )}
        </Flex>
        {!isExternalPage && (
          <Flex align='center' className='header-right' gap={20}>
            {(isTicketPage || isHrTicketPage || isOfficeTicketPage) &&
              !isOvertimeTicketPage &&
              !isBusinessTripTicketPage && (
                <Button className='header-menu-btn' onClick={handleAdd}>
                  <img src={Add} alt='Create a ticket' className='header-menu-btn-icon' />
                  Tạo phiếu mới
                </Button>
              )}
            <Notification />
            <Dropdown menu={{ items }} trigger={['click']} arrow={false}>
              <Space size={[10, 0]} className='user-profile'>
                <Space size={[4, 0]} align='center'>
                  <Icon url={User} size={16} style={{ display: 'inline-block' }} />
                  <Text strong style={{ color: 'inherit' }}>
                    Chào, {formatName(userInfo?.fullName)}
                  </Text>
                </Space>
                <CaretDownOutlined />
              </Space>
            </Dropdown>
          </Flex>
        )}
      </Flex>
      <ChangePassword open={openChangePassword} setOpen={setOpenChangePassword} />
      <Config open={openConfig} setOpen={setOpenConfig} />
      {isOpenAddTicket && (
        <TicketManagementModal setIsOpen={setIsOpenAddTicket} isOpen={isOpenAddTicket} isRaising={true} />
      )}
    </HeaderAntd>
  );
};

export default Header;
